<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container history_container tw-rounded-lg">
                    <div ref="notesHistoryContainer" class="padder tw-flex tw-flex-col tw-relative tw-h-full tw-items-center">
                        <div class="tw-flex tw-mt-4 tw-mb-10 tw-text-lg tw-w-full" v-if="isHeader">
                            <h3 class="tw-w-5/6 tw-m-0">{{ heading }}</h3>

                            <span class="tw-w-1/6 tw-mt-0 tw-text-right tw-cursor-pointer" @click="$emit('close')">
                                <font-awesome-icon icon="times"> </font-awesome-icon>
                            </span>
                        </div>

                        <div class="modal-body tw-w-full">
                            <template v-if="datatype == 'missing'">
                                <multi-accordian :items="missingHistory"></multi-accordian>
                            </template>
                            <template v-else>
                                <div class="tw-flex-col tw-border-0 tw-border-l-2 tw-border-solid tw-border-blue-800">
                                    <div class="tw-flex tw-h-10 tw-items-center tw-justify-between tw-px-4 tw-cursor-pointer" @click="showUserDetails = !showUserDetails" style="background-color: var(--brand-area-color)">
                                        <span class="">Case Timeline</span>
                                        <div class="tw-flex tw-items-center">
                                            <span class="tw-mr-6 tw-tracking-wider">{{ caseUserTimeline.case_spent_time }}</span>
                                            <img src="@/assets/icons/ico-caret-left-brand-color.svg" class="tw-h-4 tw-transform tw--rotate-90" />
                                        </div>
                                    </div>
                                    <div v-if="showUserDetails" class="scroll-bar tw-flex-col tw-overflow-y-auto" :style="{height: `${expandedTimelineHeight}px`}">
                                        <div class="tw-flex-col tw-px-4 tw-pt-4 tw-pb-2 overflow-y-auto" style="background-color: var(--brand-area-color)">
                                            <div class="tw-flex-col tw-bg-white tw-p-1 tw-mb-2" v-for="userData in caseUserTimeline.timelines" :key="userData.user_id">
                                                <div class="tw-flex tw-h-10 tw-items-center tw-justify-between tw-px-4 tw-cursor-pointer" @click="expandUserTimeline(userData.user_id)">
                                                    <span class="tw-capitalize">{{ userData.username }}</span>
                                                    <div class="tw-flex tw-items-center">
                                                        <span class="tw-mr-6">{{ userData.total_time_taken }}</span>
                                                        <img src="@/assets/icons/ico-caret-left-brand-color.svg" class="tw-h-4 tw-transform tw--rotate-90" />
                                                    </div>
                                                </div>
                                                <div v-if="expandUserId === userData.user_id" class="tw-flex-col tw-p-4">
                                                    <div class="tw-flex tw-h-8 tw-bg-white tw-px-4 tw-mb-2 tw-items-center tw-justify-between tw-text-gray-800" v-for="timelineDoc in userData.timeline" :key="timelineDoc._id" :class="actionsColor[timelineDoc.action]">
                                                        <span>{{ timelineDoc.action }}</span>
                                                        <span>{{ timelineDoc.ts }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="!showUserDetails" class="tw-mt-5">
                                    <div class="tw-mb-2">
                                        <neo-input class="tw-w-full" placeholder="Search Keywords" icon="search" iconFocus="search-focus" border="0" bg="white" inputPadding="0" v-model="filters.search"> </neo-input>
                                    </div>
                                    <div class="tw-h-10 tw-flex tw-bg-gray-100 tw-mb-5">
                                        <div v-for="(tab, index) in tabs" :key="index + tab" class="tab__item tw-flex-1 tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-cursor-pointer hover:tw-bg-gray-200" :class="currentTab === tab ? ['active__tab'] : []" @click="onTabChange(tab)">
                                            <span class="tw-text-gray-500 tw-font-bold">{{ tab }}</span>
                                        </div>
                                    </div>
                                    <div ref="tableBody" v-if="currentTab === 'Notes'" class="tw-relative" :style="{height: `${tableHeight}px`}">
                                        <div class="tw-flex tw-px-2 tw-py-2 tw-text-gray-600 tw-gap-5">
                                            <div class="tw-flex" style="flex: 2">
                                                Entity Type
                                            </div>
                                            <div class="tw-flex" style="flex: 2">
                                                Entity
                                            </div>
                                            <div class="tw-flex" style="flex: 2">
                                                Date
                                            </div>
                                            <div class="tw-flex" style="flex: 4">
                                                URL
                                            </div>
                                            <div class="tw-flex" style="flex: 3; max-width: 20rem; width: 20rem" v-if="!readOnly">
                                                Notes
                                            </div>
                                            <div class="tw-flex" style="flex: 3; max-width: 20rem; width: 20rem">
                                                Customer Notes
                                            </div>
                                        </div>
                                        <div class="table__item__container tw-h-full tw-bg-gray-100 tw-px-1 tw-flex tw-flex-col tw-overflow-y-auto tw-pb-1">
                                            <div v-for="(note, noteIndex) in getNotes" :key="'note_' + noteIndex" class="tw-flex tw-bg-white tw-mt-1 tw-items-center tw-px-2 tw-py-4 tw-gap-5">
                                                <div class="tw-flex" style="flex: 2">
                                                    <div>{{ note.main_type.toLowerCase() === "passive media" ? "Media" : note.main_type }}</div>
                                                </div>
                                                <div class="tw-flex tw-break-all" style="flex: 2">
                                                    <div>{{ note.value }}</div>
                                                </div>
                                                <div class="tw-flex" style="flex: 2">
                                                    <div>{{ note.dt }}</div>
                                                </div>
                                                <div class="tw-flex tw-px-2 tw-text-xs tw-break-all" style="flex: 4">
                                                    <a class="tw-text-blue-500" :href="note.url" target="_blank" rel="noreferrer">
                                                        {{ note.url }}
                                                    </a>
                                                </div>
                                                <div v-if="!readOnly" class="tw-flex tw-break-all" style="max-width: 20rem; width: 20rem">
                                                    <div class="tw-break-all tw-max-h-24 tw-overflow-y-auto" v-if="note.type.key === 'output'" v-html="note.notes" style="overflow-wrap: anywhere"></div>
                                                    <div class="tw-break-all" v-else style="overflow-wrap: anywhere">
                                                        {{ note.readMoreNote ? note.notes : note.notes.slice(0, 100) }}
                                                        <span class="tw-cursor-pointer tw-text-brand" v-if="note.notes.length > 100" @click="activateReadMore(note, 'note')">
                                                            {{ note.readMoreNote ? "Show Less" : "Show More" }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="tw-flex tw-break-all" style="max-width: 20rem; width: 20rem">
                                                    <div class="tw-break-all tw-max-h-24 tw-overflow-y-auto" v-if="note.type.key === 'output'" v-html="note.customer_notes" style="overflow-wrap: anywhere"></div>
                                                    <div class="tw-break-all" v-else style="overflow-wrap: anywhere">
                                                        {{ note.readMoreCustomerNote ? note.customer_notes : note.customer_notes.slice(0, 100) }}
                                                        <span class="tw-cursor-pointer tw-text-brand" v-if="note.customer_notes.length > 100" @click="activateReadMore(note, 'customer_note')">
                                                            {{ note.readMoreCustomerNote ? "Show Less" : "Show More" }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div ref="tableBody" v-if="currentTab === 'History'" class="tw-relative" :style="{height: `${tableHeight}px`}">
                                        <div class="tw-flex tw-pl-3 tw-pr-2 tw-py-2">
                                            <div class="tw-flex" style="flex: 3">
                                                Title &amp; URL
                                            </div>
                                            <div class="tw-flex" style="flex: 1">
                                                Time Spent
                                            </div>
                                            <div class="tw-flex" style="flex: 1">
                                                Timestamp
                                            </div>
                                        </div>
                                        <div class="table__item__container tw-h-full tw-bg-gray-100 tw-px-1 tw-flex tw-flex-col tw-overflow-y-auto tw-pb-1">
                                            <div v-for="(history, historyIndex) in getHistory" :key="'history_' + historyIndex" class="tw-flex tw-bg-white tw-mt-1 tw-items-center tw-px-2 tw-py-2">
                                                <div class="tw-flex tw-flex-col tw-px-2" style="flex: 3">
                                                    <div class="tw-text-gray-800 tw-text-base tw-mb-3">{{ history.title }}</div>
                                                    <div class="tw-flex tw-text-xs tw-text-blue-500">
                                                        <div class="tw-flex tw-items-center tw-cursor-pointer tw-mr-2" @click="onExpandUrl('history', historyIndex)">
                                                            <img src="@/assets/icons/ico-caret-left-brand-color.svg" class="tw-h-3 tw-transform" :class="urlId === `history-${historyIndex}` ? ['tw--rotate-90'] : ['tw-rotate-180']" />
                                                        </div>
                                                        <div class="" :id="`${'historyurl'}-${historyIndex}`" :class="urlId === `history-${historyIndex}` ? ['tw-break-all', 'tw-w-full'] : ['tw-whitespace-nowrap', 'tw-overflow-hidden', 'tw-overflow-ellipsis', 'tw-w-80']">
                                                            <a class="tw-text-blue-500" :href="history.url" target="_blank" rel="noreferrer">
                                                                {{ history.url }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="" style="flex: 1">
                                                    <span v-if="(currentPage - 1) * 10 + historyIndex < allHistory.length - 1"> {{ getTimeSpent(history, allHistory[(currentPage - 1) * 10 + historyIndex + 1]) }} </span>
                                                    <span v-else> -- </span>
                                                </div>
                                                <div class="" style="flex: 1">
                                                    <div>{{ getFormattedTimestamp(history.lastVisitTime) }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div ref="tableBody" v-if="currentTab === 'Pages Activity'" class="tw-relative" :style="{height: `${tableHeight}px`}">
                                        <div class="tw-flex tw-px-2 tw-py-2 tw-text-gray-600 tw-gap-5">
                                            <div class="tw-flex" style="flex: 4">
                                                Title &amp; URL
                                            </div>
                                            <div class="tw-flex" style="flex: 1">
                                                Total Time Spent
                                            </div>
                                            <!-- <div class="tw-flex " style="flex: 1;">Total Tabs</div> -->
                                            <!-- <div class="tw-flex" style="flex: 3">Last Updated</div> -->
                                        </div>
                                        <div class="table__item__container tw-h-full tw-bg-gray-100 tw-px-1 tw-flex tw-flex-col tw-overflow-y-auto tw-pb-1">
                                            <div v-for="(pageActivity, index) in getPagesActivity" :key="'page_activity_' + index" class="tw-flex tw-bg-white tw-mt-1 tw-items-center tw-px-2 tw-py-4 tw-gap-5">
                                                <!-- <div :title="pageActivity.title" class="tw-truncate tw-overflow-ellipsis" style="flex: 3;">
                                                    <div>{{ pageActivity.title }}</div>
                                                </div>
                                                <div class=" tw-flex tw-px-2 tw-text-xs tw-truncate" :title="pageActivity.url" style="flex: 4;">
                                                    <a class="tw-text-blue-500" :href="pageActivity.url" target="_blank" rel="noreferrer">
                                                        {{ pageActivity.url }}
                                                    </a>
                                                </div> -->
                                                <div class="tw-flex tw-flex-col tw-px-2" style="flex: 4">
                                                    <div class="tw-text-gray-800 tw-text-base tw-mb-3 tw-break-all">{{ pageActivity.title }}</div>
                                                    <div class="tw-flex tw-text-xs tw-text-blue-500">
                                                        <div class="tw-flex tw-items-center tw-cursor-pointer tw-mr-2" @click="onExpandUrl('pageActivity', index)">
                                                            <img src="@/assets/icons/ico-caret-left-brand-color.svg" class="tw-h-3 tw-transform" :class="urlId === `pageActivity-${index}` ? ['tw--rotate-90'] : ['tw-rotate-180']" />
                                                        </div>
                                                        <div class="" :id="`${'pageActivityurl'}-${index}`" :class="urlId === `pageActivity-${index}` ? ['tw-break-all', 'tw-w-full'] : ['tw-whitespace-nowrap', 'tw-overflow-hidden', 'tw-overflow-ellipsis', 'tw-w-80']">
                                                            <a class="tw-text-blue-500" :href="pageActivity.url" target="_blank" rel="noreferrer">
                                                                {{ pageActivity.url }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tw-break-all tw-ml-8" style="flex: 1">
                                                    <div>{{ (pageActivity.total_time_spent / 60).toFixed(2) }} mins</div>
                                                </div>
                                                <!-- <div style="flex: 1;">
                                                    <div>{{ pageActivity.count }}</div>
                                                </div> -->
                                                <!-- <div class="tw-break-all" style="flex: 3;">
                                                    <div>{{ getFormattedTimestamp(pageActivity.last_updated_at) }}</div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div v-if="!showUserDetails" class="tw-absolute tw-h-10 tw-bottom-0 tw-flex tw-items-center tw-h-5 tw-bg-white">
                            <neo-pagination v-if="getTotalPageCount > 0" v-model="currentPage" :totalPageCount="getTotalPageCount"></neo-pagination>
                        </div>

                        <!-- <div class="modal-footer">
                        <slot name="footer">
                        </slot>
                        <div class="close--modal tw-bg-gray-200 tw-py-4" v-if="isFooter">
                            <neo-button
                                label="Close"
                                color="white"
                                hoverText="white"
                                bg="var(--brand-color)"
                                hoverBg="var(--brand-color)"
                                padding="0.4rem 1rem"
                                @click="$emit('close')"
                            ></neo-button>
                        </div>

                    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import moment from "moment";
import {getPlatformURL, getIcon} from "@/utils/functions";
import {DateTime} from "luxon";
import axios from "@/axios";

// components
import Input from "@/components/input";
import Pagination from "@/components/pagination";
import MultiAccordian from "../../components/multilevel-accordian";

export default {
    name: "modal",
    components: {
        MultiAccordian,
        "neo-pagination": Pagination,
        "neo-input": Input,
    },
    props: {
        heading: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        datatype: {
            type: String,
            default: "",
        },
        isHeader: {
            type: Boolean,
            default: false,
        },
        isFooter: {
            type: Boolean,
            default: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            expandUserId: null,
            showUserDetails: false,
            tabs: ["Notes", "History", "Pages Activity"],
            currentTab: "",
            currentPage: 1,
            totalPage: 1,
            urlId: "",
            pageLimit: 7,
            notes: [],
            tableHeight: 0,
            caseUserTimeline: [],
            actionsColor: {
                START: "tw-bg-green-100",
                PAUSE: "tw-bg-yellow-100",
                RESUME: "tw-bg-blue-100",
                STOP: "tw-bg-red-100",
            },
            filters: {
                search: "",
            },
            notesFilterKeys: ["customer_notes", "dt", "main_type", "notes", "url", "value"],
            hiltoryFiltrerkeys: ["title", "url", "lastVisitTime"],
            totalCount: {
                Notes: 0,
                History: 0,
                "Pages Activity": 0,
            },
            expandedTimelineHeight: 0,
            pagesActivity: [],
            allHistory: [],
        };
    },
    computed: {
        missingHistory() {
            let finalObj = {children: []};
            let data = this.$store.getters.getHistoryData.missing_history;
            if (data) {
                Object.keys(data).forEach(function(key, index) {
                    finalObj.children.push({text: key, children: []});
                    Object.keys(data[key]).forEach(function(key2) {
                        if (Array.isArray(data[key][key2])) {
                            finalObj.children[index].children.push({
                                text: key2,
                                children: data[key][key2].map((v) => {
                                    return {text: v};
                                }),
                            });
                        } else {
                            finalObj.children[index].children.push({text: data[key][key2]});
                        }
                    });
                });
            }
            return finalObj;
        },
        getNotes() {
            let filteredNotes = this.notes.filter((note) => {
                for (let noteKey of this.notesFilterKeys) {
                    if (note[noteKey].toLowerCase().includes(this.filters.search.toLowerCase())) return true;
                }
            });
            this.totalCount["Notes"] = filteredNotes.length;
            let slicedNotes = filteredNotes.slice(this.currentPage * 10 - 10, this.currentPage * 10);
            return slicedNotes;
        },

        getHistory() {
            let filteredHistory = this.$store.getters.getHistoryData.case_history.history.filter((history) => {
                for (let HistoryKey of this.hiltoryFiltrerkeys) {
                    if (HistoryKey === "lastVisitTime") {
                        return this.getFormattedTimestamp(history[HistoryKey])
                            .toLowerCase()
                            .includes(this.filters.search.toLowerCase());
                    }
                    if (history[HistoryKey].toLowerCase().includes(this.filters.search.toLowerCase())) return true;
                }
            });
            this.totalCount["History"] = filteredHistory.length;
            this.allHistory = filteredHistory;
            let slicedHistory = filteredHistory.slice(this.currentPage * 10 - 10, this.currentPage * 10);
            return slicedHistory;
        },
        getTotalPageCount() {
            let count;
            // if (this.currentTab === "Notes") {
            //     count = this.totalCount / 10;
            // } else {
            //     count = this.totalCount / 10;
            // }

            count = this.totalCount[this.currentTab] / 10;

            if (count > Math.floor(count)) {
                count = Math.floor(count) + 1;
            }
            this.currentPage = 1;
            return count;
        },
        getPagesActivity() {
            let filtered = this.pagesActivity.filter((data) => data.url?.toLowerCase().includes(this.filters.search.toLowerCase()) || data.title?.toLowerCase().includes(this.filters.search.toLowerCase()));
            this.totalCount["Pages Activity"] = filtered.length;
            let slicedNotes = filtered.slice(this.currentPage * 10 - 10, this.currentPage * 10);
            return slicedNotes;
        },
    },
    async mounted() {
        await this.getCaseUserTimeline();
        await this.fetchPagesActivity();
        this.tableHeight = this.$refs.notesHistoryContainer.offsetHeight - 340;
        this.expandedTimelineHeight = this.tableHeight + 100;
        this.currentTab = this.tabs[0];
        let sortedNotes = this.$store.getters.getNotesData.case_notes.sort((a, b) => {
            return a.dt.localeCompare(b.dt);
        });
        sortedNotes = sortedNotes.reverse();
        this.notes = sortedNotes.map((note) => {
            if (note.type.key === "social") {
                note.url = this.getPlatformURL(note.main_type, note.value);
            }
            let dt = DateTime.fromISO(note.dt);
            note.dt = dt.toISODate();
            return note;
        });
    },
    methods: {
        getIcon,
        getPlatformURL,
        getTimeSpent(history, nextHistory) {
            let endTime = moment(nextHistory?.lastVisitTime).format("DD-MM-YYYY HH:mm:ss");
            let startTime = moment(history?.lastVisitTime).format("DD-MM-YYYY HH:mm:ss");
            let time = moment(endTime, "DD-MM-YYYY HH:mm:ss").diff(moment(startTime, "DD-MM-YYYY HH:mm:ss"), "seconds");
            if (time >= 0) {
                if (time < 60) {
                    let seconds = parseInt(time, 10);
                    return `${seconds} seconds`;
                } else if (time < 60 * 60) {
                    let minutes = parseInt(time / 60, 10);
                    let seconds = parseInt(time % 60, 10);
                    return `${minutes} minutes ${seconds} seconds`;
                } else {
                    let hours = parseInt(time / (60 * 60), 10);
                    let minutes = parseInt((time % (60 * 60)) / 60, 10);
                    let seconds = parseInt((time % (60 * 60)) % 60, 10);
                    return `${hours} hours ${minutes} minutes ${seconds} seconds`;
                }
            } else {
                return "--";
            }
        },
        expandUserTimeline(userID) {
            if (this.expandUserId === userID) {
                this.expandUserId = null;
            } else {
                this.expandUserId = userID;
            }
        },
        async getCaseUserTimeline() {
            const url = `case-times/users/${this.$store.getters.getCaseId}`;
            try {
                const response = await axios.get(url);
                response?.data?.data.timelines.forEach((item) =>
                    item.timeline.forEach((el) => {
                        let parsed_dt = DateTime.fromFormat(el.ts, "dd MMMM yy HH:mm:ss", {zone: "utc"});
                        if (!parsed_dt.invalid) el.ts = parsed_dt.toLocal().toFormat("dd MMM yy hh:mm:ss a");
                        return el;
                    })
                );
                this.caseUserTimeline = response.data.data;
            } catch (error) {
                console.error(error);
            }
        },
        onTabChange(tab) {
            this.currentTab = tab;
            this.currentPage = 1;
            this.urlId = "";
            this.filters.search = "";
        },
        onExpandUrl(prefix, urlId) {
            if (this.urlId === `${prefix}-${urlId}`) {
                this.urlId = "";
            } else {
                this.urlId = `${prefix}-${urlId}`;
            }
        },
        getFormattedTimestamp(ts, format = "MMMM Do YYYY, h:mm:ss a") {
            return moment(ts).format(format);
        },
        async fetchPagesActivity() {
            const {data} = await axios.get("/tab-history-status/", {params: {case_id: this.$route.params.id}});
            this.pagesActivity = Object.values(data.tab_history);
        },
        activateReadMore(row, column) {
            if (column === "note") this.$set(row, "readMoreNote", !row.readMoreNote);
            else if (column === "customer_note") this.$set(row, "readMoreCustomerNote", !row.readMoreCustomerNote);
        },
    },
};
</script>

<style scoped lang="scss">
.modal-mask {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    // flex-direction: column;
    padding: 3vh 0;
    max-height: 100vh;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    // display: flex;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    height: 100%;
    // display: table-cell;
}

.modal-container {
    width: 80%;
    margin: 0px auto;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: var(--brand-font);
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    height: calc(100% - 100px);
    overflow-y: hidden;
    overflow-x: hidden;
}

.modal-default-button {
    float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.close--modal {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
}

table {
    border-collapse: collapse !important;
}

table td {
    border: none !important;
}

.padder {
    padding: 0rem 1rem 0 1rem;
}
.table__item__container {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #a8b5c9;
        border-radius: 20rem;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #9cadc6;
    }
}
.osint-title {
    font-size: 14pt;
    margin-top: 1.25rem;
}
.osint-link {
    font-size: 11pt;
}
.link-item {
    text-decoration: none;
    color: var(--brand-color);
}
.tab__item {
    border-bottom: 2px solid rgb(228, 228, 228);
}
.active__tab {
    border-bottom: 2px solid var(--brand-color);
}
.history-url a {
    color: var(--brand-color);
    text-decoration: none;
    font-size: 0.85em;
}
.scroll-bar {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #a8b5c9;
        border-radius: 20rem;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #9cadc6;
    }
}
</style>
